<template>
  <div v-if="approvalstatus">
    <el-dialog
      :title="$t('label.submitforapproval')"
      :visible.sync="approvalstatus"
      :close-on-click-modal="false"
      :show-close="false"
      width="50%"
    >
      <span>
        <div class="distribution-again">
          <div class="distribution-body">
            <div class="distribution-body">
              <div class="body">
                <div>
                  <!-- 下一个审批人 -->
                  <div
                    style="
                      white-space: nowrap;
                      font-size: 12px;
                      margin-right: 10px;
                      line-height: 40px;
                    "
                  >
                    {{ $t("label.nextapprover") }}
                  </div>
                </div>
                <el-form
                  :model="formData"
                  :rules="rules"
                  ref="ruleForm"
                  class="demo-ruleForm"
                >
                  <el-form-item prop="people">
                    <el-input
                      v-model="formData.people"
                      readonly="readonly"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item> -->
                  <!-- <el-button
                      @click="submitForm('ruleForm')"
                      :loading="approvalLoading"
                    > -->
                  <!-- 发送到下一个审批人 -->
                  <!-- {{ $t("label.sendtonextapprover") }} -->
                  <!-- </el-button> -->
                  <!-- <el-button -->
                  <!-- @click="cancel('ruleForm')"
                      :loading="approvalLoading"
                    > -->
                  <!-- 取消 -->
                  <!-- {{ $t("label.cancel") }} -->
                  <!-- </el-button> -->
                  <!-- <div @click="submitForm('ruleForm')" class="submit-button">发送到下一个审批人</div>
              <div class="cancel-button" @click="cancel('ruleForm')">取消</div> -->
                  <!-- </el-form-item> -->
                </el-form>
                <div>
                  <!-- 查找 -->
                  <span class="search-button" @click="searchPeo"
                    >({{ $t("label.conditiontag.lookup") }})
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- 查找/查找多选 -->
          <el-dialog
            :title="$t('label.selectapprover')"
            width="60%"
            top="5%"
            :visible.sync="showSearchTable"
            :lock-scroll="true"
            :close-on-click-modal="false"
            :modal="true"
            :before-close="beforeClose"
            append-to-body
          >
            <div
              :style="{
                height: dialogBodyHeight,
                overflow: 'auto',
                padding: '0 20px',
              }"
            >
              <search-table
                ref="searchTable"
                :checked="false"
                relevant-objid="user"
                relevant-prefix="005"
                @changeSelect="changeSelect"
              />
            </div>
          </el-dialog>
          <div class="select-next-approval">
            <!-- <el-dialog title="选择批准人" -->
            <el-dialog
              :title="$t('label.selectapprover')"
              :visible.sync="isSearch"
              :modal="false"
              width="750px"
            >
              <select-next-approval
                @changeSearch="changeSearch"
              ></select-next-approval>
            </el-dialog>
          </div>
        </div>
      </span>
      <span slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <!-- 提 交 -->
        <el-button @click="cancelfun">{{ $t("label.cancel") }}</el-button>
        <el-button
          type="primary"
          @click="submitApp('ruleForm')"
          :loading="isSubmit"
          >{{ $t("label.submit") }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>



<script>
/*
 * 审批选择下一个审批人弹窗
 */
import selectNextApproval from "@/views/approval/components/select-next-approval.vue";
import { submitApproval } from "./api.js";
import detailSendCard from "@/utils/robotMessage.js";
import SearchTable from "@/components/Form/search-table";

export default {
  data() {
    return {
      //提交按钮loading
      isSubmit: false,
      //弹框高度
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      //是否展示查找多选弹框
      showSearchTable: false,
      //客户群策/机会群策ID
      detailGroupId: "",
      //记录ID
      recordId: "",
      //是否展示选择下一个审批人弹窗
      approvalstatus: false,
      //loading
      approvalLoading: false,
      //是否搜索批准人
      isSearch: false,
      //审批人ID
      fprId: "",
      //相关项ID
      relatedId: "",
      //表单数据
      formData: {
        people: "",
        remarks: "",
        textarea: "",
      },
      //表单规则
      rules: {
        // 值不能为空
        people: [
          {
            required: true,
            message: this.$i18n.t("label.value.notnull"),
            trigger: "change",
          },
        ],
      },
    };
  },
  props: {
    //展示保存并新建按钮
    saveandapproval: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    selectNextApproval,
    SearchTable,
  },

  beforeDestroy() {
    this.$Bus.$off("clear-next-approvalPeople", this.clearNextApprovalPeople);
  },
  mounted() {
    this.$Bus.$on("clear-next-approvalPeople", this.clearNextApprovalPeople);
  },
  methods: {
    clearNextApprovalPeople() {
      this.formData.people = "";
      this.isSearch = false;
    },
    /*
     * 关闭查找弹窗
     */
    beforeClose() {
      this.$nextTick(() => {
        this.$refs.searchTable.searchConditions = "";
      });
      this.showSearchTable = false;
    },
    /*
     * 父组件选中批准人
     */
    changeSelect(res) {
      if (Array.isArray(res)) {
        if (res.length === 0) {
          this.fprId = "";
          this.formData.people = "";
        } else {
          res.forEach((item) => {
            this.fprId = item.id;
            this.formData.people = item.name;
          });
        }
      } else {
        this.fprId = res.data.id;
        this.formData.people = res.data.name;
      }
      this.$refs.searchTable.searchConditions = "";
      this.showSearchTable = false;
    },
    /*
     * 关闭弹窗
     */
    cancelfun() {
      this.$Bus.$emit("recovery-submit-status");
      this.approvalstatus = false;
      this.formData.people = [];
      this.formData.textarea = "";
    },
    /*
     * 供父组件调用的方法，控制弹窗显示或隐藏
     */
    IsShowdialog(params, historyID, detailGroupId, recordId) {
      this.detailGroupId = detailGroupId;
      this.recordId = recordId;
      this.approvalstatus = params;
      this.relatedId = historyID;
    },
    /*
     * 获取审批人名称和ID
     */
    deliverName(name, id) {
      this.formData.people = name;
      this.fprId = id;
    },
    /*
     * 提交待审批
     */
    async submitopction() {
      this.isSubmit = true;
      let option = {};
      option.relatedId = this.relatedId;
      option.fprId = this.fprId;
      let result = await submitApproval(option).catch((err) => {
        this.isSubmit = false;
        this.$message({
          showClose: true,
          message: err.returnInfo,
          type: "error",
        });
      });
      if (result.result) {
        this.isSubmit = false;
        this.approvalstatus = false;
        if (this.detailGroupId != "") {
          detailSendCard(this.detailGroupId, {
            approval_value: `${localStorage.getItem("username")}${this.$i18n.t(
              "label.submitforapproval"
            )}`,
            title: this.$i18n.t("vue_label_chat_approval_message"),
            recordId: this.recordId,
            type: "APPROVAL_CARD",
            // body: [
            //   {
            //     fieldLable: "日期",
            //     fieldValue: transformTime(new Date().getTime())
            //   }
            // ]
          });
        }
        this.detailGroupId = "";
        this.recordId = "";
        if (this.saveandapproval) {
          this.$emit("approvalSuccess");
        } else {
          this.$Bus.$emit("approvalrefresh");
        }
      }
    },
    /*
     * 提交表单验证
     */
    submitApp(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitopction();
        } else {
          return false;
        }
      });
    },
    /*
     * 收起弹出框
     */
    changeSearch(i, id, name) {
      this.isSearch = false;
      this.fprId = id;
      this.formData.people = name;
    },
    /*
     * 查找批准人
     */
    searchPeo() {
      // this.isSearch = true;
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.init();
      });
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .el-textarea {
  width: 100% !important;
}
::v-deep .el-textarea__inner {
  min-height: 119px !important;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.select-next-approval {
  ::v-deep .el-dialog__body {
    padding: 16px 36px;
  }
}
::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
}
.search-button {
  white-space: nowrap;
  cursor: pointer;
  color: #006dcc;
  font-size: 12px;
  margin-left: 10px;
  line-height: 40px;
}
.distribution-again {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;
  .distribution-body {
    width: 100%;
    .distribution-title {
      height: 49px;
      font-size: 18px;
      background: #f8f8f8;
      font-weight: 500;
      color: #222222;
      line-height: 49px;
      padding-left: 20px;
    }
    .distribution-body {
      .title {
        margin-top: 14px;
        margin-left: 20px;
        font-size: 14px;
        color: #222;
      }
      .body {
        padding: 30px;
        display: flex;
        .el-form {
          .is-have {
            font-size: 12px;
            margin-left: 100px;
          }
          .el-input {
            height: 100%;
          }
          .el-form-item__label {
            font-size: 12px;
          }
          .el-input__inner {
            width: 216px;
            height: 30px;
            line-height: 30px;
          }
          .el-textarea {
            width: 431px;
          }
          .el-textarea__inner {
            height: 163px;
            resize: none;
          }
          .submit-button {
            cursor: pointer;
            width: 127px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            color: #006dcc;
            font-size: 12px;
            border-radius: 3px;
            border: 1px solid #dddbda;
            float: left;
            margin-right: 15px;
            font-weight: 400;
          }
          .cancel-button {
            cursor: pointer;
            width: 50px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            color: #006dcc;
            font-size: 12px;
            border-radius: 3px;
            border: 1px solid #dddbda;
            float: left;
            font-weight: 400;
          }
        }
      }
    }
  }
  .leftdis {
    margin-left: 230px;
  }
  .el-button {
    padding: 6px 15px;
    color: #006dcc;
    font-size: 12px;
  }
}
</style>
